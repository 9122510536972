import React from 'react';
import * as propTypes from 'lib/prop_types';

import OnlineImage from 'images/online_study.png';

function Online({ className = '' }) {
  return (
    <img
      alt="Online studies"
      className={`Online ${className}`}
      src={OnlineImage}
    />
  );
}

Online.propTypes = {
  className: propTypes.string,
};

export default Online;
