import React, { Component } from 'react';
import { Modal } from '@user-interviews/ui-design-system';
import * as propTypes from 'lib/prop_types';
import { track, trackingPropsShape } from 'lib/analytics';

class TrackedModal extends Component {
  static propTypes = {
    event: propTypes.string.isRequired,
    isOpen: propTypes.bool,
    ...trackingPropsShape,
  };

  static defaultProps = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.tracking.trackEvent({ event: this.props.event });
    }
  }

  render() {
    return <Modal {...this.props}>{this.props.children}</Modal>;
  }
}

export default track()(TrackedModal);
