import React from 'react';

import {
  CopyToClipboardButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@user-interviews/ui-design-system';

import * as propTypes from 'lib/prop_types';
import { trackingEvents } from 'lib/analytics';
// eslint-disable-next-line local-rules/css_modules
import * as styles from './non_brand_token_error_modal_contents.module.scss';

const BRAND_ADMIN_EMAIL_TEXT = `I'm trying to connect my Qualtrics account to my User Interviews
account, which will save me a lot of time tracking progress and managing payments for my research
projects. In order to enable this I need a Brand Admin's API token as part of the initial set up.

You can find it in your Qualtrics account > Account Settings > Qualtrics ID.

Once you've located it you can send it to me and I can continue the verification process
on User Interviews.

Alternatively, you can navigate to the Integrations page on User Interviews and
paste the token there. When you’ve done this, everyone else on the team can easily
connect their Qualtrics account to User Interviews!`;

function NonBrandTokenErrorModalContents({ onRequestClose }) {
  return (
    <>
      <ModalHeader
        title="Looks like this isn't a Brand Admin's token"
        titleId="api-key-modal-label"
        onRequestClose={onRequestClose}
      />
      <ModalBody>
        <p>
          You can send this message to your Brand Admin to help them look for
          the right token.
        </p>
        <section className={styles.emailText}>
          <p>
            I'm trying to connect my qualtrics account to my User Interviews
            account, which will save me a lot of time tracking progress and
            managing payments for my research projects.
          </p>
          <p>
            In order to enable this I need a Brand Admin's API token as part of
            the initial set up. &nbsp;You can find it in your Qualtrics account
            &gt; Account Settings &gt; Qualtrics ID.
          </p>
          <p>
            Once you've located it you can send it to me and I can continue the
            verification process on User Interviews.
          </p>
          <p>
            Alternatively, you can navigate to the Integrations page on User
            Interviews and paste the token there. When you’ve done this,
            everyone else on the team can easily connect their Qualtrics account
            to User Interviews!
          </p>
        </section>
        <div className={styles.emailButtonContainer}>
          <CopyToClipboardButton
            copyText={BRAND_ADMIN_EMAIL_TEXT}
            displayText="Copy to Clipboard"
            trackingEvent={
              trackingEvents.QUALTRICS_NON_BRAND_ADMIN_TOKEN_MODAL_COPY_BUTTON_CLICKED
            }
            variant="secondary"
          />
        </div>
      </ModalBody>
      <ModalFooter dismissButtonText="Close" onRequestClose={onRequestClose} />
    </>
  );
}

NonBrandTokenErrorModalContents.propTypes = {
  onRequestClose: propTypes.func.isRequired,
};

export default NonBrandTokenErrorModalContents;
