import React from 'react';
import * as propTypes from 'lib/prop_types';

import OneOnOneImage from 'images/one_on_one_interview.png';
function OneOnOne({ className = '' }) {
  return (
    <img
      alt="One-on-one interviews"
      className={`OneOnOne ${className}`}
      src={OneOnOneImage}
    />
  );
}

OneOnOne.propTypes = {
  className: propTypes.string,
};

export default OneOnOne;
