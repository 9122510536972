/* eslint-disable max-len */
import React from 'react';

export function Zoom({ className = '' }: { className?: string }) {
  return (
    <svg
      className={`Zoom ${className}`}
      data-testid="zoom-icon"
      viewBox="0 0 18 18"
    >
      <path
        d="M9 0C13.9706 0 18 4.02937 18 9C18 13.9706 13.9706 18 9 18C4.02937 18 0 13.9706 0 9C0 4.02937 4.02937 0 9 0Z"
        fill="#E5E5E4"
      />
      <path
        d="M9.00001 0.175232C13.8738 0.175232 17.8247 4.12617 17.8247 8.99995C17.8247 13.8737 13.8738 17.8247 9.00001 17.8247C4.12624 17.8247 0.175293 13.8737 0.175293 8.99995C0.175293 4.12617 4.12637 0.175232 9.00001 0.175232V0.175232Z"
        fill="white"
      />
      <path
        d="M9.00017 0.883179C13.483 0.883179 17.117 4.51724 17.117 9.00005C17.117 13.4829 13.483 17.1169 9.00017 17.1169C4.51736 17.1169 0.883301 13.4829 0.883301 9.00005C0.883301 4.51724 4.51736 0.883179 9.00017 0.883179V0.883179Z"
        fill="#4A8CFF"
      />
      <path
        d="M3.70459 6.45697V10.2714C3.70798 11.134 4.41249 11.8282 5.27167 11.8247H10.8318C10.9899 11.8247 11.117 11.6975 11.117 11.5429V7.72841C11.1136 6.86584 10.4091 6.17161 9.55009 6.17513H3.98994C3.8319 6.17513 3.70473 6.3023 3.70473 6.45697H3.70459ZM11.4709 7.94497L13.7665 6.26804C13.9658 6.10308 14.1204 6.14425 14.1204 6.44329V11.5567C14.1204 11.8969 13.9313 11.8557 13.7665 11.7319L11.4709 10.0584V7.94497V7.94497Z"
        fill="white"
      />
    </svg>
  );
}
