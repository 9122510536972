/* eslint-disable max-len */

import React from 'react';

function MessagesEmptyIcon() {
  return (
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.3338 47.5967C32.7642 46.4805 33.0002 45.2678 33.0002 43.9999V42.9999C33.0002 37.477 28.523 32.9999 23.0002 32.9999H17.7167C13.8862 29.089 13.911 22.8133 17.7912 18.9331L31.9334 4.79099C35.8386 0.885751 42.1703 0.885751 46.0755 4.79099L60.2176 18.9331C64.1229 22.8383 64.1229 29.17 60.2176 33.0752L46.0755 47.2174C42.3011 50.9918 36.2601 51.1182 32.3338 47.5967Z"
        fill="#CECBC9"
      />
      <path
        d="M32.8 41C32.5823 39.9277 32.1931 38.9178 31.6622 38H37.5C37.8978 38 38.2794 38.158 38.5607 38.4393L42 41.8787V38.9185C42 38.3074 42.3707 37.7574 42.9372 37.5281C45.3726 36.5422 47 34.1724 47 31.5V27.5C47 25.8582 46.3909 24.3153 45.3083 23.1261C44.7506 22.5134 44.7951 21.5647 45.4077 21.0071C46.0203 20.4494 46.969 20.4939 47.5267 21.1065C49.1074 22.843 50 25.1038 50 27.5V31.5C50 35.0513 48.0331 38.2372 45 39.8688V43.0858C45 44.4665 43.8807 45.5858 42.5 45.5858C41.837 45.5858 41.2011 45.3224 40.7322 44.8536L36.8787 41H32.8ZM19.1178 33C19.0403 32.5114 19 32.0104 19 31.5V27.5C19 22.2533 23.2533 18 28.5 18H40.5C41.3284 18 42 18.6716 42 19.5C42 20.3284 41.3284 21 40.5 21H28.5C24.9101 21 22 23.9101 22 27.5V31.5C22 32.0163 22.0602 32.5185 22.1739 33H19.1178Z"
        fill="#B5B0AB"
      />
      <path
        d="M40.5 25H27.5C26.6716 25 26 25.6716 26 26.5C26 27.3284 26.6716 28 27.5 28H40.5C41.3284 28 42 27.3284 42 26.5C42 25.6716 41.3284 25 40.5 25Z"
        fill="#E6E2E0"
      />
      <path
        d="M40.5 31H27.5C26.6716 31 26 31.6716 26 32.5C26 33.3284 26.6716 34 27.5 34H40.5C41.3284 34 42 33.3284 42 32.5C42 31.6716 41.3284 31 40.5 31Z"
        fill="#E6E2E0"
      />
      <path
        d="M16.4393 53.4393C16.7206 53.158 17.1022 53 17.5 53H23.5C28.1944 53 32 49.1944 32 44.5V42.5C32 37.8056 28.1944 34 23.5 34H11.5C6.80558 34 3 37.8056 3 42.5V44.5C3 45.3284 2.32843 46 1.5 46C0.671573 46 0 45.3284 0 44.5V42.5C0 36.1487 5.14873 31 11.5 31H23.5C29.8513 31 35 36.1487 35 42.5V44.5C35 50.8513 29.8513 56 23.5 56H18.1213L12.2678 61.8536C11.2915 62.8299 9.70854 62.8299 8.73223 61.8536C8.26339 61.3847 8 60.7488 8 60.0858V55.4572C5.95321 54.8038 4.12918 53.5854 2.73428 51.9442C2.19776 51.313 2.27453 50.3664 2.90575 49.8299C3.53697 49.2933 4.48361 49.3701 5.02013 50.0013C6.25046 51.4488 7.92963 52.4506 9.7984 52.8299C10.4975 52.9719 11 53.5866 11 54.3V58.8787L16.4393 53.4393Z"
        fill="#B5B0AB"
      />
      <path
        d="M25.5 39H8.5C7.67157 39 7 39.6716 7 40.5C7 41.3284 7.67157 42 8.5 42H25.5C26.3284 42 27 41.3284 27 40.5C27 39.6716 26.3284 39 25.5 39Z"
        fill="#E6E2E0"
      />
      <path
        d="M21.5 45H8.5C7.67157 45 7 45.6716 7 46.5C7 47.3284 7.67157 48 8.5 48H21.5C22.3284 48 23 47.3284 23 46.5C23 45.6716 22.3284 45 21.5 45Z"
        fill="#E6E2E0"
      />
    </svg>
  );
}

export default MessagesEmptyIcon;
