import React from 'react';
import { Navigate } from 'react-router';

import useAuthorization from 'hooks/use_authorization';
import { MATCH_TYPES } from './match_types';

type AuthorizationProps = {
  children: React.ReactNode;
  matchType?: (typeof MATCH_TYPES)[keyof typeof MATCH_TYPES];
  notTo?: string | string[];
  redirectPath?: string;
  to?: string | string[];
};

function Authorization({
  children,
  matchType = MATCH_TYPES.ALL,
  notTo,
  redirectPath,
  to,
}: AuthorizationProps) {
  const isAuthorized = useAuthorization({ matchType, notTo, to });

  if (isAuthorized) {
    return children;
  }

  return redirectPath ? <Navigate to={redirectPath} /> : null;
}

export default Authorization;
