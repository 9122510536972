/* eslint-disable max-len */
import React from 'react';
import * as propTypes from 'lib/prop_types';

const styleRed = {
  fill: '#e60023',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
};

const styleWhite = {
  fill: '#ffffff',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
};

export function Pinterest({ className }) {
  return (
    <svg
      className={`Pinterest ${className}`}
      height="312.5"
      id="svg2"
      viewBox="0 0 1278.75 312.5"
      width="1278.75"
    >
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath18">
        <path d="M 0,250 1023,250 1023,0 0,0 0,250 Z" id="path20" />
      </clipPath>
      <g id="g10" transform="matrix(1.25,0,0,-1.25,0,312.5)">
        <path
          d="m 402.996,159.887 32.312,0 0,-101.493 -32.312,0 0,101.493 z"
          id="path12"
          style={styleRed}
        />
        <g id="g14">
          <g clipPath="url(#clipPath18)" id="g16">
            <g id="g22" transform="translate(442.0549,159.8871)">
              <path
                d="m 0,0 31.122,0 0,-13.876 0.594,0 c 7.533,10.903 17.246,16.849 31.321,16.849 22.201,0 35.284,-15.858 35.284,-38.456 l 0,-66.01 -32.311,0 0,59.468 c 0,10.705 -5.352,18.04 -15.858,18.04 -10.704,0 -17.841,-8.921 -17.841,-21.409 l 0,-56.099 -32.311,0 L 0,0 Z"
                id="path24"
                style={styleRed}
              />
            </g>
            <g id="g26" transform="translate(438.5334,187.0405)">
              <path
                d="m 0,0 c 0,10.705 -8.678,19.382 -19.382,19.382 -10.704,0 -19.382,-8.677 -19.382,-19.382 0,-10.704 8.678,-19.381 19.382,-19.381 C -8.678,-19.381 0,-10.704 0,0"
                id="path28"
                style={styleRed}
              />
            </g>
            <g id="g30" transform="translate(767.6262,161.353)">
              <path
                d="m 0,0 c -0.922,0.083 -1.979,0.12 -3.216,0.12 -12.29,0 -20.615,-5.352 -27.752,-17.444 l -0.594,0 0,15.858 -30.924,0 0,-101.492 32.311,0 0,45.592 c 0,21.125 11.699,30.914 30.175,29.206 L 0,0 Z"
                id="path32"
                style={styleRed}
              />
            </g>
            <g id="g34" transform="translate(926.1896,122.4228)">
              <path
                d="m 0,0 c -12.885,2.378 -24.382,3.369 -24.382,10.505 0,6.344 6.146,9.317 14.074,9.317 8.92,0 15.066,-2.775 16.255,-11.894 l 29.735,0 c -1.586,20.021 -17.048,32.51 -45.79,32.51 -23.987,0 -43.81,-11.101 -43.81,-32.51 0,-23.786 18.832,-28.544 36.475,-31.518 13.479,-2.379 25.769,-3.37 25.769,-12.488 0,-6.542 -6.145,-10.11 -15.858,-10.11 -9.745,0 -16.19,4.123 -18.166,12.488 l -30.789,0 c 2.266,-20.864 20.58,-33.5 49.153,-33.5 26.563,0 46.187,11.497 46.187,33.698 C 38.853,-7.533 17.841,-2.974 0,0"
                id="path36"
                style={styleRed}
              />
            </g>
            <g id="g38" transform="translate(817.7492,139.8666)">
              <path
                d="m 0,0 c 10.506,0 18.038,-7.731 19.228,-19.824 l -40.042,0 C -18.634,-7.731 -12.29,0 0,0 m 1.784,-61.252 c -13.282,0 -20.814,8.523 -22.797,22.201 l 72.949,0 c 0.198,20.615 -5.748,38.258 -17.841,49.358 -8.722,7.929 -20.219,12.687 -34.691,12.687 -30.923,0 -52.133,-23.192 -52.133,-53.522 0,-30.724 20.616,-53.918 54.116,-53.918 12.884,0 23.193,3.37 31.717,9.317 8.92,6.145 14.867,14.867 17.047,23.985 l -31.518,0 C 15.857,-57.487 10.308,-61.252 1.784,-61.252"
                id="path40"
                style={styleRed}
              />
            </g>
            <g id="g42" transform="translate(1021.9494,82.3271)">
              <path
                d="m 0,0 c -1.497,-0.059 -3.507,-0.146 -5.354,-0.146 -6.939,0 -11.299,1.983 -11.299,9.912 l 0,47.575 16.653,0 0,20.219 -16.653,0 0,32.113 -31.519,0 0,-32.113 -9.917,0 0,-20.219 9.917,0 0,-54.513 c 0,-21.806 13.48,-27.95 32.312,-27.95 7.185,0 12.616,0.538 15.86,1.171 L 0,0 Z"
                id="path44"
                style={styleRed}
              />
            </g>
            <g id="g46" transform="translate(598.0783,82.2812)">
              <path
                d="m 0,0 c -1.289,-0.05 -2.777,-0.1 -4.167,-0.1 -6.937,0 -11.299,1.983 -11.299,9.912 l 0,47.575 15.466,0 0,20.219 -15.466,0 0,32.113 -31.518,0 0,-32.113 -10.51,0 0,-20.219 10.51,0 0,-54.513 c 0,-21.806 13.479,-27.95 32.311,-27.95 6.372,0 11.361,0.424 14.673,0.961 L 0,0 Z"
                id="path48"
                style={styleRed}
              />
            </g>
            <g id="g50" transform="translate(668.4582,120.0429)">
              <path
                d="M 0,0 C -1.189,12.092 -8.723,19.824 -19.229,19.824 -31.519,19.824 -37.862,12.092 -40.043,0 L 0,0 Z m -71.957,-10.704 c 0,30.329 21.21,53.521 52.134,53.521 14.47,0 25.968,-4.757 34.69,-12.686 C 26.959,19.03 32.905,1.388 32.707,-19.228 l -72.947,0 c 1.981,-13.677 9.514,-22.201 22.796,-22.201 8.523,0 14.074,3.766 16.848,10.109 l 31.519,0 c -2.181,-9.118 -8.127,-17.84 -17.048,-23.986 -8.523,-5.946 -18.831,-9.316 -31.716,-9.316 -33.501,0 -54.116,23.193 -54.116,53.918"
                id="path52"
                style={styleRed}
              />
            </g>
            <g id="g54" transform="translate(346.8825,133.5224)">
              <path
                d="M 0,0 C 12.687,0 19.823,7.533 19.823,18.833 19.823,30.131 12.488,37.069 0,37.069 l -24.78,0 L -24.78,0 0,0 Z m -57.492,66.605 56.11,0 c 17.245,0 29.932,-4.757 38.654,-13.083 9.119,-8.523 14.273,-20.616 14.273,-34.888 0,-27.157 -18.832,-46.386 -47.972,-46.386 l -28.353,0 0,-47.376 -32.712,0 0,141.733 z"
                id="path56"
                style={styleRed}
              />
            </g>
            <g id="g58" transform="translate(245.0344,124.9999)">
              <path
                d="m 0,0 c 0,-67.374 -54.618,-121.992 -121.992,-121.992 -67.374,0 -121.992,54.618 -121.992,121.992 0,67.375 54.618,121.992 121.992,121.992 C -54.618,121.992 0,67.375 0,0"
                id="path60"
                style={styleWhite}
              />
            </g>
            <g id="g62" transform="translate(123.0422,246.9921)">
              <path
                d="m 0,0 c -67.374,0 -121.992,-54.618 -121.992,-121.992 0,-51.682 32.155,-95.863 77.535,-113.638 -1.066,9.652 -2.03,24.496 0.423,35.033 2.217,9.521 14.305,60.638 14.305,60.638 0,0 -3.649,7.308 -3.649,18.112 0,16.963 9.832,29.627 22.074,29.627 10.408,0 15.435,-7.814 15.435,-17.184 0,-10.467 -6.664,-26.116 -10.103,-40.618 -2.874,-12.142 6.089,-22.044 18.065,-22.044 21.682,0 38.349,22.862 38.349,55.863 0,29.209 -20.988,49.631 -50.957,49.631 -34.711,0 -55.085,-26.035 -55.085,-52.941 0,-10.485 4.039,-21.728 9.079,-27.84 0.997,-1.208 1.143,-2.266 0.846,-3.499 -0.926,-3.853 -2.984,-12.139 -3.388,-13.835 -0.532,-2.233 -1.768,-2.707 -4.08,-1.631 -15.236,7.092 -24.762,29.368 -24.762,47.26 0,38.482 27.96,73.823 80.605,73.823 42.319,0 75.206,-30.156 75.206,-70.457 0,-42.043 -26.509,-75.879 -63.303,-75.879 -12.362,0 -23.983,6.422 -27.961,14.008 0,0 -6.117,-23.292 -7.6,-29 -2.754,-10.596 -10.189,-23.878 -15.162,-31.981 11.414,-3.534 23.544,-5.44 36.12,-5.44 67.375,0 121.993,54.618 121.993,121.992 C 121.993,-54.618 67.375,0 0,0"
                id="path64"
                style={styleRed}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Pinterest.propTypes = {
  className: propTypes.string,
};
