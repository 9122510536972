/* eslint-disable max-len */

import React from 'react';

export function Webex({ className }: { className?: string }) {
  return (
    <svg className={`Webex${className && ` ${className}`}`} viewBox="0 0 150 150">
      <defs>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient" x1="8.45" x2="122.24" y1="788.21" y2="742.21">
          <stop offset=".13" stopColor="#1cbbf3"/>
          <stop offset=".34" stopColor="#1f99a5"/>
          <stop offset=".45" stopColor="#209ca6"/>
          <stop offset=".51" stopColor="#24a4aa"/>
          <stop offset=".57" stopColor="#2bb3b0"/>
          <stop offset=".6" stopColor="#30bdb4"/>
          <stop offset=".79" stopColor="#38ba90"/>
          <stop offset="1" stopColor="#60f05a"/>
        </linearGradient>
        <radialGradient cx="-697.1" cy="260.22" fx="-697.1" fy="260.22" gradientTransform="translate(26923.54 43831.64) rotate(74.32) scale(70.85 53.85)" gradientUnits="userSpaceOnUse" id="radial-gradient" r=".76">
          <stop offset="0" stopColor="#227b60"/>
          <stop offset=".14" stopColor="#227b60" stopOpacity=".81"/>
          <stop offset=".58" stopColor="#227b60" stopOpacity=".23"/>
          <stop offset=".78" stopColor="#227b60" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-696.08" cy="252.93" fx="-696.08" fy="252.93" gradientTransform="translate(8632.89 33591.24) rotate(91.15) scale(47.91 36.41)" gradientUnits="userSpaceOnUse" id="radial-gradient-2" r=".76">
          <stop offset="0" stopColor="#227b60"/>
          <stop offset=".25" stopColor="#227b60" stopOpacity=".7"/>
          <stop offset=".71" stopColor="#227b60" stopOpacity=".2"/>
          <stop offset=".92" stopColor="#227b60" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-694.92" cy="251.43" fx="-694.92" fy="251.43" gradientTransform="translate(8820.13 30895.67) rotate(88.6) scale(44.66 31.71)" gradientUnits="userSpaceOnUse" id="radial-gradient-3" r=".76">
          <stop offset="0" stopColor="#227b60"/>
          <stop offset=".07" stopColor="#227b60" stopOpacity=".9"/>
          <stop offset=".34" stopColor="#227b60" stopOpacity=".51"/>
          <stop offset=".59" stopColor="#227b60" stopOpacity=".23"/>
          <stop offset=".79" stopColor="#227b60" stopOpacity=".06"/>
          <stop offset=".92" stopColor="#227b60" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-692.51" cy="247.67" fx="-692.51" fy="247.67" gradientTransform="translate(7041.85 24872.25) rotate(88.6) scale(36.06 25.6)" gradientUnits="userSpaceOnUse" id="radial-gradient-4" r=".76">
          <stop offset="0" stopColor="#227b60"/>
          <stop offset=".36" stopColor="#227b60" stopOpacity=".63"/>
          <stop offset=".92" stopColor="#227b60" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-689.35" cy="240.05" fx="-689.35" fy="240.05" gradientTransform="translate(4994.47 19776.31) rotate(88.6) scale(28.76 18.41)" gradientUnits="userSpaceOnUse" id="radial-gradient-5" r=".76">
          <stop offset="0" stopColor="#20735a"/>
          <stop offset=".15" stopColor="#20745a" stopOpacity=".93"/>
          <stop offset=".38" stopColor="#21755c" stopOpacity=".74"/>
          <stop offset=".67" stopColor="#21785d" stopOpacity=".43"/>
          <stop offset="1" stopColor="#227b60" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-691.11" cy="272.78" fx="-691.11" fy="272.78" gradientTransform="translate(33181.62 4311.18) rotate(23.8) scale(46.28 34.71)" gradientUnits="userSpaceOnUse" id="radial-gradient-6" r=".76">
          <stop offset="0" stopColor="#227a61"/>
          <stop offset=".36" stopColor="#227a61" stopOpacity=".56"/>
          <stop offset=".72" stopColor="#227a61" stopOpacity=".16"/>
          <stop offset=".9" stopColor="#227a61" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-693.96" cy="268.5" fx="-693.96" fy="268.5" gradientTransform="translate(43801.61 12375.59) rotate(35.27) scale(61.72 56.43)" gradientUnits="userSpaceOnUse" id="radial-gradient-7" r=".76">
          <stop offset="0" stopColor="#3cd9c3"/>
          <stop offset=".07" stopColor="#3bd7c1" stopOpacity=".9"/>
          <stop offset=".41" stopColor="#38ceb9" stopOpacity=".42"/>
          <stop offset=".65" stopColor="#36c8b4" stopOpacity=".12"/>
          <stop offset=".76" stopColor="#35c6b2" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-702.99" cy="267.02" fx="-702.99" fy="267.02" gradientTransform="translate(7418894.93 2737347.9) rotate(35.27) scale(10963.3 7691.4)" id="radial-gradient-8" r=".76"/>
        <radialGradient cx="-698.95" cy="258.55" fx="-698.95" fy="258.55" gradientTransform="translate(9935.06 40737.43) rotate(100.97) scale(54.43 67.27)" gradientUnits="userSpaceOnUse" id="radial-gradient-9" r=".76">
          <stop offset="0" stopColor="#237f66" stopOpacity=".8"/>
          <stop offset=".06" stopColor="#2c8c70" stopOpacity=".65"/>
          <stop offset=".21" stopColor="#3da785" stopOpacity=".37"/>
          <stop offset=".35" stopColor="#4aba93" stopOpacity=".17"/>
          <stop offset=".47" stopColor="#51c59c" stopOpacity=".04"/>
          <stop offset=".56" stopColor="#54c99f" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-705.15" cy="275.95" fx="-705.15" fy="275.95" gradientTransform="translate(15334.5 -52193.02) rotate(-50.82) scale(71.13 76.82)" gradientUnits="userSpaceOnUse" id="radial-gradient-10" r=".76">
          <stop offset="0" stopColor="#167c8a"/>
          <stop offset=".04" stopColor="#167c8a" stopOpacity=".93"/>
          <stop offset=".33" stopColor="#177f8d" stopOpacity=".43"/>
          <stop offset=".54" stopColor="#17818f" stopOpacity=".12"/>
          <stop offset=".65" stopColor="#178290" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-701.94" cy="278.84" fx="-701.94" fy="278.84" gradientTransform="translate(33510.94 -38492.34) rotate(-31.66) scale(69.39 54.82)" gradientUnits="userSpaceOnUse" id="radial-gradient-11" r=".77">
          <stop offset=".05" stopColor="#177d87"/>
          <stop offset=".11" stopColor="#177e89" stopOpacity=".8"/>
          <stop offset=".21" stopColor="#177f8b" stopOpacity=".52"/>
          <stop offset=".32" stopColor="#17818d" stopOpacity=".29"/>
          <stop offset=".42" stopColor="#17818f" stopOpacity=".13"/>
          <stop offset=".51" stopColor="#178290" stopOpacity=".03"/>
          <stop offset=".6" stopColor="#178290" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-721.1" cy="270.7" fx="-721.1" fy="270.7" gradientTransform="translate(-26009.94 -16843.54) rotate(-130.54) scale(41.34 32.55)" gradientUnits="userSpaceOnUse" id="radial-gradient-12" r=".76">
          <stop offset=".26" stopColor="#177f8a"/>
          <stop offset=".27" stopColor="#177f8a" stopOpacity=".98"/>
          <stop offset=".38" stopColor="#17808c" stopOpacity=".68"/>
          <stop offset=".5" stopColor="#17818d" stopOpacity=".43"/>
          <stop offset=".62" stopColor="#17818f" stopOpacity=".24"/>
          <stop offset=".74" stopColor="#17828f" stopOpacity=".11"/>
          <stop offset=".86" stopColor="#178290" stopOpacity=".03"/>
          <stop offset="1" stopColor="#178290" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-719.68" cy="257.84" fx="-719.68" fy="257.84" gradientTransform="translate(-28850.36 6673.47) rotate(-173.11) scale(38.78)" gradientUnits="userSpaceOnUse" id="radial-gradient-13" r=".76">
          <stop offset="0" stopColor="#13656e"/>
          <stop offset=".11" stopColor="#13646e" stopOpacity=".73"/>
          <stop offset=".27" stopColor="#12646e" stopOpacity=".41"/>
          <stop offset=".42" stopColor="#12636e" stopOpacity=".18"/>
          <stop offset=".55" stopColor="#12636e" stopOpacity=".05"/>
          <stop offset=".66" stopColor="#12636e" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-728.17" cy="277.95" fx="-728.17" fy="277.95" gradientTransform="translate(-15134.38 -14020.02) rotate(-119.32) scale(27.12 22.78)" gradientUnits="userSpaceOnUse" id="radial-gradient-14" r=".77">
          <stop offset=".13" stopColor="#146a73"/>
          <stop offset=".24" stopColor="#156f79" stopOpacity=".79"/>
          <stop offset=".45" stopColor="#167783" stopOpacity=".45"/>
          <stop offset=".65" stopColor="#167d8a" stopOpacity=".2"/>
          <stop offset=".82" stopColor="#17818e" stopOpacity=".05"/>
          <stop offset=".95" stopColor="#178290" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-707.52" cy="300.52" fx="-707.52" fy="300.52" gradientTransform="translate(5115.16 -18271) rotate(-55.99) scale(25.51 20.21)" gradientUnits="userSpaceOnUse" id="radial-gradient-15" r=".76">
          <stop offset="0" stopColor="#198e9c"/>
          <stop offset=".1" stopColor="#198e9c" stopOpacity=".87"/>
          <stop offset=".41" stopColor="#198e9c" stopOpacity=".5"/>
          <stop offset=".67" stopColor="#198e9c" stopOpacity=".23"/>
          <stop offset=".88" stopColor="#198e9c" stopOpacity=".06"/>
          <stop offset="1" stopColor="#198e9c" stopOpacity="0"/>
        </radialGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-2" x1="16.12" x2="66.15" y1="715.22" y2="761.52">
          <stop offset=".13" stopColor="#2bf9ff"/>
          <stop offset=".27" stopColor="#29f9ff" stopOpacity=".64"/>
          <stop offset=".4" stopColor="#27faff" stopOpacity=".3"/>
          <stop offset=".49" stopColor="#26faff" stopOpacity=".08"/>
          <stop offset=".54" stopColor="#26faff" stopOpacity="0"/>
        </linearGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-3" x1="-12.31" x2="46.1" y1="790.8" y2="774.23">
          <stop offset="0" stopColor="#26faff"/>
          <stop offset=".06" stopColor="#26faff" stopOpacity=".88"/>
          <stop offset=".24" stopColor="#26faff" stopOpacity=".51"/>
          <stop offset=".39" stopColor="#26faff" stopOpacity=".23"/>
          <stop offset=".5" stopColor="#26faff" stopOpacity=".06"/>
          <stop offset=".55" stopColor="#26faff" stopOpacity="0"/>
        </linearGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-4" x1="2.96" x2="68.96" y1="788.84" y2="765.32">
          <stop offset="0" stopColor="#21deff"/>
          <stop offset=".04" stopColor="#21deff" stopOpacity=".88"/>
          <stop offset=".17" stopColor="#21deff" stopOpacity=".51"/>
          <stop offset=".27" stopColor="#21deff" stopOpacity=".23"/>
          <stop offset=".34" stopColor="#21deff" stopOpacity=".06"/>
          <stop offset=".38" stopColor="#21deff" stopOpacity="0"/>
        </linearGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-5" x1="65.89" x2="102.72" y1="754.71" y2="814.78">
          <stop offset=".62" stopColor="#21deff" stopOpacity="0"/>
          <stop offset=".66" stopColor="#21deff" stopOpacity=".06"/>
          <stop offset=".73" stopColor="#21deff" stopOpacity=".23"/>
          <stop offset=".83" stopColor="#21deff" stopOpacity=".51"/>
          <stop offset=".96" stopColor="#21deff" stopOpacity=".88"/>
          <stop offset="1" stopColor="#21deff"/>
        </linearGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-6" x1="106.89" x2="101.11" y1="777.17" y2="748.95">
          <stop offset="0" stopColor="#44b044"/>
          <stop offset=".11" stopColor="#44b044" stopOpacity=".8"/>
          <stop offset=".27" stopColor="#44b044" stopOpacity=".52"/>
          <stop offset=".43" stopColor="#44b044" stopOpacity=".29"/>
          <stop offset=".58" stopColor="#44b044" stopOpacity=".13"/>
          <stop offset=".7" stopColor="#44b044" stopOpacity=".03"/>
          <stop offset=".8" stopColor="#44b044" stopOpacity="0"/>
        </linearGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-7" x1="26.04" x2="147.9" y1="747.95" y2="788.3">
          <stop offset="0" stopColor="#3066fb"/>
          <stop offset=".38" stopColor="#2f64f7"/>
          <stop offset=".74" stopColor="#2857d9"/>
          <stop offset="1" stopColor="#3c7eff"/>
        </linearGradient>
        <radialGradient cx="-700.85" cy="257.15" fx="-700.85" fy="257.15" gradientTransform="translate(5387.57 61326.48) rotate(98.69) scale(85.26 56.27)" gradientUnits="userSpaceOnUse" id="radial-gradient-16" r=".76">
          <stop offset="0" stopColor="#1a3d9c"/>
          <stop offset=".4" stopColor="#1a3d9c" stopOpacity=".42"/>
          <stop offset=".7" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-703.32" cy="266.59" fx="-703.32" fy="266.59" gradientTransform="translate(374982.21 7464343.27) rotate(98.69) scale(10490.7 5654.53)" id="radial-gradient-17" r=".76"/>
        <radialGradient cx="-703.56" cy="266.7" fx="-703.56" fy="266.7" gradientTransform="translate(46126.13 8839663.32) rotate(98.62) scale(12493.9 5159.7)" id="radial-gradient-18" r=".76"/>
        <radialGradient cx="-698.38" cy="258.51" fx="-698.38" fy="258.51" gradientTransform="translate(19711.28 50120.68) rotate(83.09) scale(74.52 52)" gradientUnits="userSpaceOnUse" id="radial-gradient-19" r=".76">
          <stop offset="0" stopColor="#1a3d9c"/>
          <stop offset=".16" stopColor="#1a3d9c" stopOpacity=".69"/>
          <stop offset=".33" stopColor="#1a3d9c" stopOpacity=".4"/>
          <stop offset=".49" stopColor="#1a3d9c" stopOpacity=".18"/>
          <stop offset=".61" stopColor="#1a3d9c" stopOpacity=".05"/>
          <stop offset=".7" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-697.3" cy="259.56" fx="-697.3" fy="259.56" gradientTransform="translate(25531.67 44886.96) rotate(74.64) scale(71.62 48.7)" gradientUnits="userSpaceOnUse" id="radial-gradient-20" r=".76">
          <stop offset="0" stopColor="#18388f"/>
          <stop offset=".37" stopColor="#193b96" stopOpacity=".45"/>
          <stop offset=".7" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-688.56" cy="255.36" fx="-688.56" fy="255.36" gradientTransform="translate(13971.9 20063.08) rotate(74.64) scale(33.3 31.63)" gradientUnits="userSpaceOnUse" id="radial-gradient-21" r=".76">
          <stop offset="0" stopColor="#18388f"/>
          <stop offset=".09" stopColor="#183992" stopOpacity=".78"/>
          <stop offset=".22" stopColor="#193b96" stopOpacity=".5"/>
          <stop offset=".35" stopColor="#193c98" stopOpacity=".28"/>
          <stop offset=".47" stopColor="#1a3c9a" stopOpacity=".13"/>
          <stop offset=".59" stopColor="#1a3d9c" stopOpacity=".03"/>
          <stop offset=".7" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-688.6" cy="255.36" fx="-688.6" fy="255.36" gradientTransform="translate(13972.7 20068.77) rotate(74.64) scale(33.3 31.63)" gradientUnits="userSpaceOnUse" id="radial-gradient-22" r=".76">
          <stop offset="0" stopColor="#1a3d9c" stopOpacity=".8"/>
          <stop offset="0" stopColor="#1a3d9c" stopOpacity=".78"/>
          <stop offset=".24" stopColor="#1a3d9c" stopOpacity=".45"/>
          <stop offset=".44" stopColor="#1a3d9c" stopOpacity=".2"/>
          <stop offset=".6" stopColor="#1a3d9c" stopOpacity=".05"/>
          <stop offset=".7" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-697.41" cy="261.21" fx="-697.41" fy="261.21" gradientTransform="translate(34357.67 45733.81) rotate(67.03) scale(79.47 52.74)" gradientUnits="userSpaceOnUse" id="radial-gradient-23" r=".76">
          <stop offset="0" stopColor="#1a3d9c"/>
          <stop offset=".4" stopColor="#1a3d9c" stopOpacity=".56"/>
          <stop offset=".48" stopColor="#1a3d9c" stopOpacity=".43"/>
          <stop offset=".69" stopColor="#1a3d9c" stopOpacity=".12"/>
          <stop offset=".79" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-695.66" cy="256.75" fx="-695.66" fy="256.75" gradientTransform="translate(19588.96 37474.22) rotate(76.1) scale(58.95 38.92)" gradientUnits="userSpaceOnUse" id="radial-gradient-24" r=".76">
          <stop offset="0" stopColor="#1a3d9c"/>
          <stop offset=".06" stopColor="#1a3d9c" stopOpacity=".88"/>
          <stop offset=".21" stopColor="#1a3d9c" stopOpacity=".61"/>
          <stop offset=".36" stopColor="#1a3d9c" stopOpacity=".39"/>
          <stop offset=".51" stopColor="#1a3d9c" stopOpacity=".22"/>
          <stop offset=".67" stopColor="#1a3d9c" stopOpacity=".1"/>
          <stop offset=".83" stopColor="#1a3d9c" stopOpacity=".02"/>
          <stop offset="1" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-695.66" cy="256.75" fx="-695.66" fy="256.75" gradientTransform="translate(19588.96 37474.22) rotate(76.1) scale(58.95 38.92)" gradientUnits="userSpaceOnUse" id="radial-gradient-25" r=".76">
          <stop offset="0" stopColor="#1b3e9e"/>
          <stop offset=".06" stopColor="#1b3e9e" stopOpacity=".87"/>
          <stop offset=".21" stopColor="#1b3e9d" stopOpacity=".61"/>
          <stop offset=".36" stopColor="#1a3d9d" stopOpacity=".39"/>
          <stop offset=".52" stopColor="#1a3d9c" stopOpacity=".22"/>
          <stop offset=".67" stopColor="#1a3d9c" stopOpacity=".1"/>
          <stop offset=".83" stopColor="#1a3d9c" stopOpacity=".02"/>
          <stop offset="1" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-692.77" cy="253.57" fx="-692.77" fy="253.57" gradientTransform="translate(14874.56 28577.02) rotate(76.1) scale(45.1 29.77)" gradientUnits="userSpaceOnUse" id="radial-gradient-26" r=".76">
          <stop offset="0" stopColor="#183991"/>
          <stop offset=".03" stopColor="#183992" stopOpacity=".95"/>
          <stop offset=".18" stopColor="#193a95" stopOpacity=".66"/>
          <stop offset=".34" stopColor="#193b97" stopOpacity=".42"/>
          <stop offset=".5" stopColor="#1a3c99" stopOpacity=".24"/>
          <stop offset=".66" stopColor="#1a3d9b" stopOpacity=".1"/>
          <stop offset=".82" stopColor="#1a3d9c" stopOpacity=".03"/>
          <stop offset="1" stopColor="#1a3d9c" stopOpacity="0"/>
        </radialGradient>
        <radialGradient cx="-692.03" cy="251.22" fx="-692.03" fy="251.22" gradientTransform="translate(9153.07 24304.55) rotate(87.14) scale(35.63 31.37)" gradientUnits="userSpaceOnUse" id="radial-gradient-27" r=".76">
          <stop offset=".04" stopColor="#2453d6" stopOpacity=".6"/>
          <stop offset=".2" stopColor="#2453d6" stopOpacity=".49"/>
          <stop offset=".52" stopColor="#2453d6" stopOpacity=".2"/>
          <stop offset=".73" stopColor="#2453d6" stopOpacity="0"/>
        </radialGradient>
        <linearGradient gradientTransform="translate(0 -694.19)" gradientUnits="userSpaceOnUse" id="linear-gradient-8" x1="105.26" x2="74.46" y1="698.13" y2="774.25">
          <stop offset="0" stopColor="#3774ff"/>
          <stop offset=".05" stopColor="#3774ff" stopOpacity=".91"/>
          <stop offset=".16" stopColor="#3774ff" stopOpacity=".66"/>
          <stop offset=".32" stopColor="#3774ff" stopOpacity=".28"/>
          <stop offset=".43" stopColor="#3774ff" stopOpacity="0"/>
        </linearGradient>
      </defs>
      <path d="M0,0H150V150H0V0Z" fill="none"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#linear-gradient)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-2)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-3)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-4)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-5)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-6)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-7)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-8)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-9)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-10)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-11)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-12)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-13)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-14)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#radial-gradient-15)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#linear-gradient-2)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#linear-gradient-3)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#linear-gradient-4)"/>
      <path d="M35.55,76.07c6.66,22.19,16.32,35.94,23.78,4.2-.72-.1,15.16-42.11,15.83-43.73,6.12-8.15,15.98-13.17,26.22-13.07,20.22-1.56,41.2,26.49,35.7,40.06-3.76,9.82-17.89,11.91-24.39,3.67-2.26-2.81-3.16-6.37-5.12-9.48-3.78-7.04-8.78-7.74-12,.15-2.69,5.88-4.14,11.99-5.58,18.19l-6.12,19.88-8.56,18.88c-6.13,8-15.87,12.76-25.92,12.84C9.23,125.68,5.74,72.15,2.52,56.04c-.45-4.44-.02-6.53,1.84-10.02,4.93-8.69,18.56-9.17,24.16-.92,1.99,2.75,2.37,6.27,2.75,9.56,.84,7.19,2.29,14.37,4.28,21.41h0Z" fill="url(#linear-gradient-5)"/>
      <path d="M65.74,61.47l9.4-25c10.4-14.41,32.65-17.52,46.41-6.12,9.63,7.26,15.37,21.64,15.37,21.64,2.44,5.25,1.2,12.11-2.98,16.13-5.72,5.95-16.09,5.42-21.25-.99-3.42-3.79-6.42-17.98-13.53-14.45-5.31,5.26-6.47,13.47-8.49,20.34-.02,1.33-6.27,20.98-6.8,22.86l-18.12-34.4h0Z" fill="url(#linear-gradient-6)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.49,11.46,7.66,18.27,.36,10.76-12.16,18.14-21.27,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.4,8.62,10.78,15.06,.42,7.22,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#linear-gradient-7)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-16)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-17)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-18)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-19)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-20)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-21)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-22)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-23)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-24)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-25)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-26)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.86,11.46,8.03,18.27,.36,10.76-12.53,18.14-21.64,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#radial-gradient-27)"/>
      <path d="M133.71,39.22c-1.22,0-2.45,.15-3.59,.46,3.45,5.66,7.49,11.46,7.66,18.27,.36,10.76-12.16,18.14-21.27,12.39-3.24,13.67-13.66,43.34-22.17,20.34-4.14-12.24-5.45-20.74-8.79-32.11C76.21,18.62,38.33,13.35,20.49,39.68c6.96,1.62,10.66,8.29,10.78,15.06,.84,7.19,2.22,14.22,4.21,21.18,11.49-35.32,18.3-26.3,23.7-4.28,5.2,22.24,11.14,45.31,25.99,52.06,19.55,10.51,41-.38,49.23-19.04,6.31-12.44,10.05-25.87,12.23-39.6,.64-5.48,2.4-11.28,.54-16.67-2.09-5.41-7.7-9.21-13.46-9.17Z" fill="url(#linear-gradient-8)"/>
    </svg>

  );
}
