import { Component } from 'react';
import {
  CheckboxButton,
  CheckboxButtonGroup,
  FormControlLabel,
} from '@user-interviews/ui-design-system';
import * as propTypes from 'lib/prop_types';

import { arrayToChunks } from 'lib/collections';

export default class CheckboxListFilter extends Component {
  static propTypes = {
    columnCount: propTypes.number,
    options: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.oneOfType([propTypes.number, propTypes.string])
          .isRequired,
        name: propTypes.string.isRequired,
        projectCount: propTypes.number,
      }).isRequired,
    ).isRequired,
    title: propTypes.string.isRequired,
    values: propTypes.oneOfType([
      propTypes.arrayOf(propTypes.number),
      propTypes.arrayOf(propTypes.string),
    ]).isRequired,
    onChange: propTypes.func.isRequired,
  };

  static defaultProps = {
    columnCount: 2,
  };

  handleOptionChanged = (event) => {
    const values = [...this.props.values];
    const inputNode = event.target;
    const changedValue = Number.isNaN(+event.target.value)
      ? event.target.value
      : Number.parseInt(event.target.value);

    /* Every once in a while someone in ops run into a bug that causes a null
       to be added to the status_ids array but I cant seem to reproduce it. This is
       kind of a hack but should hopefully resolve the issue for now. */
    if (changedValue !== null) {
      if (inputNode.checked) {
        if (!values.includes(changedValue)) {
          values.push(changedValue);
        }
      } else if (values.includes(changedValue)) {
        values.splice(values.indexOf(changedValue), 1);
      }
    }

    this.props.onChange(values);
  };

  renderOptions() {
    const { columnCount } = this.props;
    const columns = arrayToChunks(this.props.options, columnCount);
    const columnClass = columnCount ? `col-sm-${12 / columnCount}` : '';

    return columns.map((column) => (
      <CheckboxButtonGroup key={column.map((option) => option.id).join(',')}>
        {column.map((option) => (
          <FormControlLabel
            bordered={false}
            checked={this.props.values.includes(option.id)}
            className={columnClass}
            Control={CheckboxButton}
            id={option.name}
            key={option.id}
            text={
              <div className="option">
                {option.name}
                {Number.isFinite(option.projectCount) && (
                  <span className="badge bg-transparent text-dark">
                    ({option.projectCount})
                  </span>
                )}
              </div>
            }
            value={option.id}
            onChange={this.handleOptionChanged}
          />
        ))}
      </CheckboxButtonGroup>
    ));
  }

  render() {
    return (
      <>
        <h4>{this.props.title}</h4>
        {this.renderOptions()}
      </>
    );
  }
}
