import React from 'react';
import classnames from 'classnames';

/* eslint-disable max-len, react/jsx-sort-props */
export function Lyssna({ className = '' }: { className?: string }) {
  return (
    <svg
      aria-label="Lyssna logo"
      className={classnames('Maze', className)}
      data-testid="lyssna-icon"
      role="img"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
      }}
      version="1.1"
      viewBox="0 0 225 225"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="#ddfdf2"
          d="M -0.5,-0.5 C 74.5,-0.5 149.5,-0.5 224.5,-0.5C 224.5,74.5 224.5,149.5 224.5,224.5C 149.5,224.5 74.5,224.5 -0.5,224.5C -0.5,149.5 -0.5,74.5 -0.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#126f73"
          d="M 90.5,106.5 C 89.3117,121.483 87.3117,136.483 84.5,151.5C 82.6222,160.157 80.2888,168.824 77.5,177.5C 79.8059,182.24 83.6393,184.574 89,184.5C 91.5912,184.435 94.0912,183.935 96.5,183C 101.373,178.751 104.873,173.584 107,167.5C 105.69,159.57 104.357,151.57 103,143.5C 101.693,130.187 101.36,116.854 102,103.5C 105.206,88.067 108.872,72.7336 113,57.5C 118.201,45.2458 125.201,44.2458 134,54.5C 136.412,65.3756 137.078,76.3756 136,87.5C 132.776,103.403 130.109,119.403 128,135.5C 124.575,144.784 120.575,153.784 116,162.5C 115.882,166.616 117.216,170.283 120,173.5C 134.068,188.83 147.068,187.83 159,170.5C 160.308,168.866 161.142,167.032 161.5,165C 150.896,138.545 146.396,111.045 148,82.5C 150.556,74.0409 151.89,65.3742 152,56.5C 153.935,45.1224 159.102,35.6224 167.5,28C 175.51,26.0868 181.343,28.9201 185,36.5C 188.011,45.7486 189.511,55.2486 189.5,65C 187.907,80.5228 186.074,96.0228 184,111.5C 179.076,129.032 174.576,146.699 170.5,164.5C 174.781,174.682 181.281,183.349 190,190.5C 192.291,193.455 191.791,195.788 188.5,197.5C 187.5,197 186.5,196.5 185.5,196C 178.619,188.623 171.952,181.123 165.5,173.5C 160.226,180.278 153.893,185.778 146.5,190C 134.672,192.504 124.506,189.337 116,180.5C 114.753,177.671 113.253,175.005 111.5,172.5C 104.414,191.693 92.2475,196.026 75,185.5C 68.3389,194.605 59.8389,196.772 49.5,192C 40.5725,179.967 35.0725,166.467 33,151.5C 32.3333,136.833 32.3333,122.167 33,107.5C 33.7764,99.3965 35.1098,91.3965 37,83.5C 40.4471,81.6474 42.2805,82.6474 42.5,86.5C 40.2018,94.6677 39.0352,103.001 39,111.5C 38.3333,125.5 38.3333,139.5 39,153.5C 40.864,165.489 45.364,176.323 52.5,186C 60.7664,190.035 66.433,187.701 69.5,179C 63.5908,161.008 60.5908,142.508 60.5,123.5C 59.8135,106.327 62.3135,89.6608 68,73.5C 70.5,69.6667 73,65.8333 75.5,62C 79.7796,59.5228 83.2796,60.3561 86,64.5C 88.0734,69.2373 89.4067,74.2373 90,79.5C 90.4998,88.4938 90.6664,97.4938 90.5,106.5 Z"
        />
      </g>
      <g>
        <path
          fill="#dafbf2"
          d="M 78.5,67.5 C 79.9778,67.238 81.3112,67.5713 82.5,68.5C 83.7607,72.0613 84.594,75.7279 85,79.5C 86.5094,109.39 83.176,138.723 75,167.5C 74.586,167.957 74.086,168.291 73.5,168.5C 71.7165,164.703 70.5499,160.703 70,156.5C 65.9028,129.937 66.9028,103.604 73,77.5C 75.0906,74.2595 76.9239,70.9262 78.5,67.5 Z"
        />
      </g>
      <g>
        <path
          fill="#dafbf2"
          d="M 122.5,54.5 C 127.566,53.9085 130.066,56.2418 130,61.5C 130.667,70.8333 130.667,80.1667 130,89.5C 127.465,104.847 124.798,120.18 122,135.5C 119.386,142.005 116.386,148.338 113,154.5C 109.329,138.445 107.996,122.112 109,105.5C 111.358,91.3764 114.358,77.3764 118,63.5C 118.986,60.1943 120.486,57.1943 122.5,54.5 Z"
        />
      </g>
      <g>
        <path
          fill="#dbfcf2"
          d="M 169.5,33.5 C 171.927,33.523 174.26,34.023 176.5,35C 181.975,43.6498 184.308,53.1498 183.5,63.5C 182.457,78.8809 180.957,94.2142 179,109.5C 175.279,124.998 170.945,140.332 166,155.5C 153.041,121.916 151.041,87.5825 160,52.5C 162.126,45.5821 165.292,39.2488 169.5,33.5 Z"
        />
      </g>
      <g>
        <path
          fill="#bcebe8"
          d="M 90.5,106.5 C 90.6663,113.508 90.4996,120.508 90,127.5C 88.2553,134.046 87.2553,140.713 87,147.5C 86.3918,149.066 85.5585,150.4 84.5,151.5C 87.3117,136.483 89.3117,121.483 90.5,106.5 Z"
        />
      </g>
    </svg>
  );
}
