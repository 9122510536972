import React from 'react';

/* eslint-disable max-len */
/* eslint-disable react/jsx-sort-props */

export function Google() {
  return (
    <svg
      className="Google"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.14 9.70456C18.14 9.06637 18.0827 8.45274 17.9764 7.86365H9.5V11.345H14.3436C14.135 12.47 13.5009 13.4232 12.5477 14.0614V16.3196H15.4564C17.1582 14.7527 18.14 12.4455 18.14 9.70456Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49976 18.5C11.9298 18.5 13.967 17.6941 15.4561 16.3195L12.5475 14.0613C11.7416 14.6013 10.7107 14.9204 9.49976 14.9204C7.15567 14.9204 5.17158 13.3372 4.46385 11.21H1.45703V13.5418C2.93794 16.4831 5.98158 18.5 9.49976 18.5Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46409 11.2099C4.28409 10.6699 4.18182 10.0931 4.18182 9.49995C4.18182 8.90677 4.28409 8.32995 4.46409 7.78995V5.45813H1.45727C0.847727 6.67313 0.5 8.04768 0.5 9.49995C0.5 10.9522 0.847727 12.3268 1.45727 13.5418L4.46409 11.2099Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49976 4.07955C10.8211 4.07955 12.0075 4.53364 12.9402 5.42545L15.5216 2.84409C13.9629 1.39182 11.9257 0.5 9.49976 0.5C5.98158 0.5 2.93794 2.51682 1.45703 5.45818L4.46385 7.79C5.17158 5.66273 7.15567 4.07955 9.49976 4.07955Z"
        fill="#EA4335"
      />
    </svg>
  );
}
