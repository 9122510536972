import React, { Component } from 'react';
import classNames from 'classnames';
import {
  LoadingOverlay,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@user-interviews/ui-design-system';
import * as propTypes from 'lib/prop_types';

// The <Prompt> Modal is suitable for cases where the content is one or more
// inputs and the CTA represents form submission.

let incrementingId = 0;

export default class Prompt extends Component {
  static propTypes = {
    children: propTypes.node.isRequired,
    className: propTypes.string,
    closingIsDisabled: propTypes.bool,
    isOpen: propTypes.bool.isRequired,
    loading: propTypes.bool,
    submitButtonLabel: propTypes.string,
    title: propTypes.string.isRequired,

    onRequestClose: propTypes.func,
    onSubmit: propTypes.func.isRequired,
  };

  static defaultProps = {
    closingIsDisabled: false,
    loading: false,
    submitButtonLabel: 'Submit',
  };

  titleId = `Prompt-${incrementingId++}`;

  aria = { labelledby: this.titleId };

  handleSubmit = (event) => {
    event.preventDefault();

    if (event.target.reportValidity()) {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <Modal
        aria={this.aria}
        isOpen={this.props.isOpen}
        onRequestClose={
          this.props.closingIsDisabled ? undefined : this.props.onRequestClose
        }
      >
        <LoadingOverlay visible={this.props.loading} />
        <div
          className={classNames('Prompt', this.props.className)}
          role="document"
        >
          <ModalHeader
            title={this.props.title}
            titleId={this.titleId}
            onRequestClose={this.props.onRequestClose}
          />
          <form onSubmit={this.handleSubmit}>
            <ModalBody>{this.props.children}</ModalBody>
            <ModalFooter
              closingIsDisabled={this.props.closingIsDisabled}
              onRequestClose={this.props.onRequestClose}
            >
              <button className="btn btn-primary" type="submit">
                {this.props.submitButtonLabel}
              </button>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    );
  }
}
