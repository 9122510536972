import React from 'react';
import * as propTypes from 'lib/prop_types';

import MultiDayImage from 'images/multi_day_study.png';

function MultiDay({ className = '' }) {
  return (
    <img
      alt="Multi-day studies"
      className={`MultiDay ${className}`}
      src={MultiDayImage}
    />
  );
}

MultiDay.propTypes = {
  className: propTypes.string,
};

export default MultiDay;
